<template>
    <el-main>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="回收员姓名：" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入回收员姓名"></el-input>
            </el-form-item>
            <el-form-item label="选择用户：" prop="pass">
                <div class="closeUser">
                    <div>
                        <el-input disabled placeholder="请选择用户" v-model="ruleForm.openid"></el-input>
                        <el-button type="primary" @click="chooseUserFlag = true">选择</el-button>
                    </div>
                    <div v-if="chooseUserInfo.avatar" class="userInfoBox">
                        <img :src="chooseUserInfo.avatar" alt="" />
                        <div class="name">{{ chooseUserInfo.nickname }}</div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="联系电话：" prop="pass">
                <el-input v-model="ruleForm.tel" placeholder="请输入回收员联系电话"></el-input>
            </el-form-item>
            <el-form-item label="回收员类别：" prop="pass">
                <el-radio-group v-model="ruleForm.type" :disabled="recycler_id">
                    <el-radio :label="1">专职</el-radio>
                    <el-radio :label="2">兼职</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="所属区域：" prop="pass">
                <div class="areaArr">
                    <el-cascader v-model="ruleForm.areaArr" :props="{ value: 'id', label: 'name', children: '_child' }"
                        placeholder="请选择或搜索" filterable :options="areaList"></el-cascader>
                    <div class="tips">用于判定前端用户提交订单是否满足服务范围，请保持所属区域与下面服务区域属于同一区域</div>
                </div>
            </el-form-item>
            <el-form-item label="服务范围：" prop="pass">
                <el-radio-group v-model="ruleForm.is_diy">
                    <el-radio :label="0">按半径</el-radio>
                    <el-radio :label="1">自定义</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="地址定位：" prop="pass">
                <!-- 按范围 自定义 -->
                <div class="locationBox">
                    <el-input disabled v-model="ruleForm.longitude"></el-input>
                    <el-input disabled v-model="ruleForm.latitude"></el-input>
                    <el-button type="primary" @click="() => (locationFlag = true)">定位</el-button>
                </div>
            </el-form-item>
            <el-form-item label="服务地址：" prop="pass">
                <el-input disabled v-model="ruleForm.address" placeholder="请进行定位服务"></el-input>
            </el-form-item>
            <el-form-item label="服务区域：" prop="pass">
                <GaoDe ref="showGaode" :initialization="Boolean(recycler_id)" :FunctionMode="1"
                    :circularOrline="ruleForm.is_diy" :options="{ center: [ruleForm.longitude, ruleForm.latitude] }">
                </GaoDe>
            </el-form-item>
        </el-form>
        <Preservation @preservation="Preservation"></Preservation>
        <!-- 选择用户弹框 -->
        <el-dialog title="选择用户" :visible.sync="chooseUserFlag" width="1000px">
            <ListComponents v-if="chooseUserFlag" :styleMethon="7" @getListItem="getUserInfo"></ListComponents>
        </el-dialog>
        <!-- 打开高德地图进行定位 -->
        <el-dialog title="定位" :visible.sync="locationFlag" width="1000px">
            <GaoDe v-if="locationFlag" :initialization="locationFlag" ref="searchGaode" id="searchId" :FunctionMode="2"
                :options="{ center: [ruleForm.longitude, ruleForm.latitude], keyword: ruleForm.address }"></GaoDe>
            <span slot="footer" class="dialog-footer">
                <el-button @click="locationFlag = false">取 消</el-button>
                <el-button type="primary" @click="sureSearch">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import GaoDe from '@/components/gaode.vue';
import Preservation from '@/components/preservation';
import ListComponents from '@/components/listComponents';
export default {
    components: {
        GaoDe,
        ListComponents,
        Preservation,
    },
    data () {
        return {
            // 选择用户弹框
            chooseUserFlag: false,
            chooseUserInfo: {},
            // 表单输入项
            ruleForm: {
                name: '',
                openid: '',
                tel: '',
                type: 1,
                areaArr: [],
                is_diy: 0,
                is_open: 1,
                province_id: '',
                city_id: '',
                area_id: '',
                address: '',
                services: 0,
                longitude: '116.397451',
                latitude: '39.909187',
                scope_text: [],
                scope: '',
            },
            // 表单规则校验
            rules: {},
            areaList: [],
            // 定位弹框
            locationFlag: false,
            // 编辑所特需的数据
            recycler_id: null,
            recycler_info: {},
        };
    },
    watch: {
        'ruleForm.longitude' (val) {
            this.$refs.showGaode.defaultCenter = [this.ruleForm.longitude, this.ruleForm.latitude];
        },
    },
    created () {
        this.getArea();
        // 检测是否为编辑
        if (this.$route.query.id) {
            this.recycler_id = this.$route.query.id;
            this.getRecyclerInfo();
        }
    },

    methods: {
        // 编辑时 获取回收员信息
        getRecyclerInfo () {
            this.$axios
                .post(this.$api.O2O.recycler.recylerInfo, {
                    id: this.recycler_id,
                })
                .then(res => {
                    if (res.code === 0) {
                        this.ruleForm = res.result;
                        this.ruleForm.areaArr = [res.result.province_id, res.result.city_id, res.result.area_id];
                        this.chooseUserInfo.avatar = res.result.avatar;
                        this.chooseUserInfo.nickname = res.result.nick_name;
                        this.chooseUserInfo.id = res.result.user_id;
                        if (!this.chooseUserInfo.is_diy) {
                            this.ruleForm.scope = Number(this.ruleForm.scope) / 1000;
                        }
                        let data = this.ruleForm.is_diy === 0 ? Number(this.ruleForm.scope) : JSON.parse(this.ruleForm.scope_text);
                        this.$refs.showGaode.edieInit(this.ruleForm.is_diy, data);
                    }
                });
        },
        getArea () {
            this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
                if (res.code == 0) {
                    this.areaList = res.result.list;
                }
            });
        },
        getUserInfo (data) {
            this.chooseUserInfo = data;
            this.ruleForm.openid = data.mini_openid;
            this.chooseUserFlag = false;
        },
        // 确认使用该坐标
        sureSearch () {
            let { defaultSearch, defaultCenter } = this.$refs.searchGaode;
            this.ruleForm.longitude = defaultCenter[0];
            this.ruleForm.latitude = defaultCenter[1];
            this.ruleForm.address = defaultSearch;
            this.locationFlag = false;
        },
        // 保存设置
        Preservation () {
            let obj = {
                name: this.ruleForm.name,
                user_id: this.chooseUserInfo.id,
                tel: this.ruleForm.tel,
                type: this.ruleForm.type,
                province_id: this.ruleForm.areaArr[0],
                city_id: this.ruleForm.areaArr[1],
                area_id: this.ruleForm.areaArr[2],
                is_diy: this.ruleForm.is_diy,
                address: this.ruleForm.address,
                longitude: this.ruleForm.longitude,
                latitude: this.ruleForm.latitude,
            };
            if (this.recycler_id) {
                obj.id = this.recycler_id;
            }
            if (obj.is_diy === 1) {
                obj.scope_text = this.$refs.showGaode.lngLatList;
            } else if (obj.is_diy === 0) {
                obj.scope = this.$refs.showGaode.circularRadius * 1000;
            }
            let url = this.recycler_id ? this.$api.O2O.recycler.editRecyler : this.$api.O2O.recycler.addRecyler;
            this.$axios.post(url, obj).then(res => {
                if (res.code === 0) {
                    let str = this.recycler_id ? '编辑成功' : '添加成功';
                    this.$message.success(str);
                    this.$router.push('/user/recyclerList');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;

    .closeUser {
        .el-input {
            width: 330px !important;
        }

        .userInfoBox {
            margin-top: 10px;
            display: flex;
            align-items: center;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-right: 20px;
                border: 1px solid #e4e4e4;
            }
        }
    }

    .locationBox {
        display: flex;

        .el-input {
            width: 165px !important;
        }
    }

    .demo-ruleForm {
        margin-bottom: 60px;

        .el-input,
        .el-cascader {
            width: 400px;
        }
    }

    .areaArr {
        display: flex;
        align-items: center;

        .tips {
            margin-left: 20px;
            color: #c0c4cc;
        }
    }
}</style>
